import React from 'react';
import { useSelector } from 'react-redux';
import { Handle, NodeProps, Position } from 'reactflow';
import { Box, css, styled } from '@mui/system';

import { elevations } from '@forethought-technologies/forethought-elements';
import { NODE_WIDTH } from '../constants';
import useRemoveNode from '../hooks/useRemoveNode';
import { EditMenuSlot } from 'src/components/email-builder/components/DroppableEmailBuilderComponent';
import VeriticalMenu from 'src/components/email-builder/components/VerticalMenu';
import {
  removeEmailWorkflowStep,
  selectEmailTargetStepId,
  selectEmailWorkflowState,
  setEmailTargetStepId,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { useAppDispatch } from 'src/store/hooks';

interface NodeWrapperProps extends NodeProps {
  children: React.ReactNode;
  deleteDisabledMessage?: string;
  isActive?: boolean;
  isPlaceholder?: boolean;
  isVerticalMenuVisible?: boolean;
  onDelete?: () => void;
  title?: string;
}

export default function NodeWrapper({
  children,
  data,
  deleteDisabledMessage,
  id,
  isActive = false,
  isPlaceholder = false,
  isVerticalMenuVisible = false,
  onDelete,
  selected,
  title,
}: NodeWrapperProps) {
  const dispatch = useAppDispatch();
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const targetStepId = useSelector(selectEmailTargetStepId);

  const handleRemove = useRemoveNode(id);

  return (
    <>
      <Container
        isActive={isActive}
        isHighlighted={targetStepId === id}
        isPlaceholder={isPlaceholder}
        // remove the highlighted step if there's any
        onClick={() => dispatch(setEmailTargetStepId(null))}
        selected={selected}
        title={title}
      >
        {children}
        {isVerticalMenuVisible && selected && (
          <EditMenuSlot>
            <VeriticalMenu
              deleteDisabledMessage={deleteDisabledMessage}
              isDragIconVisible={false}
              onClickDelete={() => {
                if (onDelete) {
                  onDelete();
                } else if (emailWorkflow) {
                  const { email_workflow_id, last_modified_date, version } =
                    emailWorkflow;
                  if (!isPlaceholder && data.stepId) {
                    dispatch(
                      removeEmailWorkflowStep({
                        emailWorkflowId: email_workflow_id,
                        lastModifiedDate: last_modified_date,
                        stepId: data.stepId,
                        version,
                      }),
                    );
                  }
                  handleRemove();
                }
              }}
              ref={null}
            />
          </EditMenuSlot>
        )}
      </Container>

      <StyledHandle
        isConnectable={false}
        position={Position.Top}
        type='target'
      />
      <StyledHandle
        isConnectable={false}
        position={Position.Bottom}
        type='source'
      />
    </>
  );
}

const Container = styled(Box, {
  shouldForwardProp: propName =>
    !['isActive', 'isPlaceholder', 'selected'].includes(propName as string),
})<{
  isActive: boolean;
  isHighlighted: boolean;
  isPlaceholder: boolean;
  selected: boolean;
}>`
  border-radius: 8px;
  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted
      ? theme.palette.colors.blue[200]
      : theme.palette.colors.white};
  border-color: ${props =>
    props.selected || props.isActive
      ? props.theme.palette.colors.purple[500]
      : props.theme.palette.colors.white};
  border-style: solid;
  border-width: 1px;
  box-shadow: ${elevations.z1};
  padding: 0;
  min-width: ${NODE_WIDTH - 40}px;
  max-width: ${NODE_WIDTH + 40}px;
  cursor: pointer;
  line-height: 1.2;
  position: relative;

  ${props =>
    props.isPlaceholder &&
    css`
      border: 1px dashed
        ${props.selected || props.isActive
          ? props.theme.palette.colors.purple[500]
          : props.theme.palette.colors.grey[600]};
      box-shadow: none;
      color: ${props.theme.palette.colors.grey[600]};
    `}
`;

const StyledHandle = styled(Handle)`
  visibility: hidden;
`;
