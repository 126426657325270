import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  SelectDropdown,
  Tabs,
  theme,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import addPurpleIcon from '../../../../assets/images/add-purple-icon.svg';
import {
  selectActionSettings,
  selectArticleSuggestionFeedbackSettings,
  setArticleSuggestionFeedbackSettings,
} from '../../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { updateActionSettings } from '../../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { KnowledgeConfigurationFields } from '../knowledge-configuration-fields';
import { addArticleSource } from './addArticleSource';
import ArticleSource from './ArticleSource';
import { FeedbackTab } from './FeebackTab';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { formatArticleSourceQueries } from 'src/components/customization-panel/article-suggestion-settings-panel/formatArticleSourceQueries';
import { validateKnowledgeConfigurationPrompt } from 'src/components/knowledge-configuration-prompt-text-field/helpers';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCustomizableActionId } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ArticleSuggestionActionSettings,
  BaseArticleConfigurationFields,
} from 'src/types/workflowBuilderAPITypes';
import { canFeedbackButtonBeRemoved } from 'src/utils/areTransitionBranchesFilled';
import { ActionBuilderActionTypes } from 'src/utils/enums';

const DEFAULT_ARTICLE_CONFIG_FIELDS = {
  allow_generative_ai: false,
  knowledge_configuration_prompt: null,
  should_override_global_knowledge_configuration_prompt: false,
} as const;

interface ArticleSuggestionSettingsPanelProps {
  actionType: ActionBuilderActionTypes;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (areSettingsUnsaved: boolean) => void;
}

const ArticleSuggestionSettingsPanel: React.FC<
  React.PropsWithChildren<ArticleSuggestionSettingsPanelProps>
> = ({
  actionType = ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const settings = useSelector(selectActionSettings);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const isLoadingData = canvasData.isLoadingCustomization;
  const actionId = useSelector(selectCustomizableActionId);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  // consts
  const [tabIndex, setTabIndex] = useState(0);
  const [shouldShowErrors, setshouldShowErrors] = useState(false);
  const [articleConfigurationFields, setArticleConfigurationFields] =
    useState<BaseArticleConfigurationFields>(DEFAULT_ARTICLE_CONFIG_FIELDS);

  const configFields =
    settings.configuration_fields as ArticleSuggestionActionSettings;
  const isDynamicArticleSuggestionEnabled = featureFlags.includes(
    'knowledge_article_workflow',
  );
  const isIRStreamEnabled = featureFlags.includes('ir_stream');
  const isInstructionalNoteUIEnabled = featureFlags.includes(
    'instructional_notes_for_kr',
  );
  const isGenerativeAIToggleEnabled = featureFlags.includes(
    'generative_ai_toggle',
  );
  const isFeedbackRemovable =
    isDynamicArticleSuggestionEnabled &&
    canFeedbackButtonBeRemoved(canvasData, actionId);

  const [articleSources, setArticleSources] = useState<
    ArticleSuggestionActionSettings['article_sources']
  >([]);

  const [topArticlesNumber, setTopArticlesNumber] = useState('1');
  const [granularInputVariable, setGranularInputVariable] = useState<
    string | undefined
  >(undefined);
  const [readArticleAction, setReadArticleAction] = useState('new_tab');
  const articleSuggestionFeedbackSettings = useSelector(
    selectArticleSuggestionFeedbackSettings,
  );

  const isUnsaved = !isEqual(configFields?.article_sources, articleSources);

  const isMissingFields = articleSources.some(articleSource => {
    if (!articleSource.doc_type) {
      return true;
    }

    return articleSource.filter_queries.some(filterQuery => {
      if (
        filterQuery.operator === 'is empty' ||
        filterQuery.operator === 'is not empty'
      ) {
        return false;
      }

      return !filterQuery.field || !filterQuery.value;
    });
  });

  const validationFailed = () => {
    return (
      (articleSuggestionFeedbackSettings &&
        articleSuggestionFeedbackSettings.button_prompt.length > 48) ||
      (articleSuggestionFeedbackSettings &&
        articleSuggestionFeedbackSettings.helpful_button_text.length > 24) ||
      (articleSuggestionFeedbackSettings &&
        articleSuggestionFeedbackSettings.not_helpful_button_text.length > 24)
    );
  };

  useEffect(() => {
    setAreActionSettingsUnsaved(isUnsaved);
  }, [isUnsaved, setAreActionSettingsUnsaved]);

  useEffect(() => {
    const configFields =
      settings.configuration_fields as ArticleSuggestionActionSettings;

    setArticleSources(configFields?.article_sources || []);
    setTopArticlesNumber(String(configFields?.top_n_articles));
    setGranularInputVariable(configFields?.input_variable);

    if (configFields) {
      setReadArticleAction(configFields.read_article_action);
    }
    if (configFields) {
      dispatch(
        setArticleSuggestionFeedbackSettings({
          button_prompt:
            configFields.button_prompt ||
            'Was I able to help you resolve your question?',
          helpful_button_text:
            configFields.helpful_button_text || 'Yes, thank you!',
          not_helpful_button_text:
            configFields.not_helpful_button_text || 'No, I need more help.',
          show_buttons: configFields.show_buttons ?? true,
        }),
      );

      setArticleConfigurationFields({
        allow_generative_ai: configFields.allow_generative_ai ?? false,
        knowledge_configuration_prompt:
          configFields.knowledge_configuration_prompt ?? null,
        should_override_global_knowledge_configuration_prompt: Boolean(
          configFields.should_override_global_knowledge_configuration_prompt,
        ),
      });
    }
  }, [settings, dispatch]);

  const validationError = validateKnowledgeConfigurationPrompt({
    prompt: articleConfigurationFields.knowledge_configuration_prompt ?? '',
    shouldUseLongerNoteLength: featureFlags.includes(
      'allow_longer_instructional_note',
    ),
  });

  return (
    <>
      {isLoadingData ? (
        <LoadingSkeleton />
      ) : (
        <Box
          bgcolor={theme.palette.colors.white}
          borderRadius='8px'
          display='flex'
          flexDirection='column'
          height='calc(100vh - 200px)'
          justifyContent='space-between'
          overflow='auto'
          padding='24px'
          paddingTop='16px'
        >
          <Box>
            <Box mb={2}>
              {isDynamicArticleSuggestionEnabled &&
              configFields.hand_off_when_not_found ? (
                <Tabs
                  a11yProps={index => {
                    const tab =
                      index === 0 ? 'dynamic article suggestion' : 'feedback';
                    return {
                      'aria-controls': `${tab}-tab`,
                      id: `${tab}-dynamic-article-suggestion-tab`,
                    };
                  }}
                  aria-label='dynamic article suggestion tabs'
                  onChange={(_, index) => setTabIndex(index)}
                  tabs={['Dynamic Article Suggestion', 'Feedback']}
                  typographyVariant='font20'
                  value={tabIndex}
                />
              ) : (
                <Typography variant='font20'>Article Suggestion</Typography>
              )}
            </Box>
            {tabIndex === 0 && (
              <>
                {isIRStreamEnabled && isInstructionalNoteUIEnabled && (
                  <Section>
                    <KnowledgeConfigurationFields
                      error={validationError}
                      knowledgeConfigurationPrompt={
                        articleConfigurationFields.knowledge_configuration_prompt ??
                        ''
                      }
                      onChangeKnowledgeConfigurationPrompt={value =>
                        setArticleConfigurationFields({
                          ...articleConfigurationFields,
                          knowledge_configuration_prompt: value,
                        })
                      }
                      onToggleShouldOverrideGlobal={e =>
                        setArticleConfigurationFields({
                          ...articleConfigurationFields,
                          should_override_global_knowledge_configuration_prompt:
                            e.target.checked,
                        })
                      }
                      shouldOverrideGlobal={
                        articleConfigurationFields.should_override_global_knowledge_configuration_prompt
                      }
                    />
                  </Section>
                )}
                {isGenerativeAIToggleEnabled && (
                  <Section>
                    <Box alignItems='center' display='flex' marginX={1}>
                      <FormControlLabel
                        control={
                          <Toggle
                            aria-label='Enable Generative AI'
                            checked={
                              articleConfigurationFields.allow_generative_ai
                            }
                            disabled={false}
                            onChange={e =>
                              setArticleConfigurationFields({
                                ...articleConfigurationFields,
                                allow_generative_ai: e.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Box
                            alignItems='center'
                            display='inline-flex'
                            marginX={2}
                          >
                            Generative AI
                          </Box>
                        }
                      />
                    </Box>
                  </Section>
                )}
                <Section>
                  <Box display='flex' flexDirection='column' gap={3}>
                    <Box display='flex' flexDirection='column' gap={1}>
                      <Box display='flex' gap={0.5}>
                        <Typography variant='font14Medium'>
                          Read Article Action
                        </Typography>
                      </Box>
                      <SelectDropdown
                        id='readArticleAction'
                        onChange={e => {
                          setReadArticleAction(e.target.value);
                        }}
                        options={
                          configFields?.read_article_action_options || []
                        }
                        value={readArticleAction}
                      />
                    </Box>
                  </Box>
                </Section>
                {actionType ===
                  ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR && (
                  <Section>
                    <ContextVariableSelectDropdown
                      label='Input Context Variable'
                      onChange={e => {
                        setGranularInputVariable(e);
                      }}
                      shouldIncludeSystemContextVariables
                      value={granularInputVariable}
                    />
                  </Section>
                )}
                {articleSources.map((articleSource, index) => (
                  <Section key={index}>
                    <ArticleSource
                      articleSourceIndex={index}
                      articleSources={articleSources}
                      connectorId={articleSource.connector_id}
                      docType={articleSource.doc_type}
                      filterQueries={articleSource?.filter_queries || []}
                      isRemoveActionEnabled={articleSources.length > 1}
                      setArticleSources={setArticleSources}
                    />
                  </Section>
                ))}
                <Section>
                  <Button
                    fullWidth
                    onClick={() =>
                      setArticleSources(addArticleSource(articleSources))
                    }
                    variant='ghost'
                  >
                    <AddIcon src={addPurpleIcon} />
                    Add article source
                  </Button>
                </Section>
              </>
            )}
            {tabIndex === 1 && (
              <FeedbackTab
                isFeedbackRemovable={isFeedbackRemovable}
                shouldShowErrors={shouldShowErrors}
              />
            )}
          </Box>
          <Box
            alignItems='center'
            display='flex'
            gap={2}
            justifyContent='flex-end'
          >
            <Button
              onClick={() => {
                onDiscard();
                dispatch(setArticleSuggestionFeedbackSettings(null));
              }}
              variant='secondary'
            >
              Cancel
            </Button>
            <Button
              disabled={isMissingFields || Boolean(validationError)}
              onClick={() => {
                if (validationFailed()) {
                  setshouldShowErrors(true);
                  setTabIndex(1);
                  return;
                }
                const configFields = {
                  ...settings.configuration_fields,
                  ...articleConfigurationFields,
                } as ArticleSuggestionActionSettings;

                const actionSettings =
                  isDynamicArticleSuggestionEnabled &&
                  configFields.hand_off_when_not_found
                    ? {
                        action_type: actionType,
                        configuration_fields: {
                          ...configFields,
                          ...articleSuggestionFeedbackSettings,
                          article_sources: formatArticleSourceQueries(
                            articleSources,
                            configFields.available_article_sources,
                          ),
                          read_article_action: readArticleAction,
                          top_n_articles: Number(topArticlesNumber),
                        },
                      }
                    : {
                        action_type: actionType,
                        configuration_fields: {
                          ...configFields,
                          article_sources: formatArticleSourceQueries(
                            articleSources,
                            configFields.available_article_sources,
                          ),
                          read_article_action: readArticleAction,
                          top_n_articles: Number(topArticlesNumber),
                        },
                      };

                if (
                  actionType ===
                  ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR
                ) {
                  actionSettings.configuration_fields.input_variable =
                    granularInputVariable;
                }

                dispatch(updateActionSettings({ ...actionSettings }));
                dispatchActionEditedTrackingEvent({
                  actionType: actionType,
                });
                setActionPanelVisibilityParameters('hidden');
                setAreActionSettingsUnsaved(false);
                dispatch(setCustomizableActionId(''));
                dispatch(setArticleSuggestionFeedbackSettings(null));
                setArticleConfigurationFields(DEFAULT_ARTICLE_CONFIG_FIELDS);
                onDismiss();
              }}
              variant='main'
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ArticleSuggestionSettingsPanel;

const Section = styled(Box)`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

export const AddIcon = styled('img')`
  margin-right: 5px;
`;
