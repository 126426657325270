import { DynamicCardTranscriptWidgetComponent } from 'src/slices/workflow-preview/types';

export type TranscriptComponentType =
  | 'output'
  | 'input'
  | 'execution'
  | 'indicator';

export type TranscriptMessageType =
  | 'text'
  | 'button_selection'
  | 'csat_submission'
  | 'dynamic_card_selection'
  | 'dynamic_card'
  | 'article_card'
  | 'intent_trigger'
  | 'image'
  | 'embed'
  | 'execution'
  | 'related_articles'
  | 'quick_feedback'
  | 'goto_workflow'
  | 'chat_handoff_status_message'
  | 'indicator'
  | 'sentiment_analysis';

interface BaseTranscriptComponent {
  component_type: TranscriptComponentType;
  message_type: TranscriptMessageType;
  timestamp: string;
}

export interface TextTranscriptComponent extends BaseTranscriptComponent {
  message?: string;
  should_render_as_markdown?: boolean;
}

export interface QuickFeedbackTranscriptComponent
  extends BaseTranscriptComponent {
  additional_feedback?: string;
  document_ids: string[];
  is_useful: boolean;
}

interface RelatedArticle {
  document_id: string;
  link: string;
  title: string;
}
export interface RelatedArticlesTranscriptComponent
  extends BaseTranscriptComponent {
  articles: RelatedArticle[];
  quick_feedback?: QuickFeedbackTranscriptComponent;
}

export interface ArticleCardTranscriptComponent
  extends BaseTranscriptComponent {
  link?: string;
  preview: string;
  quick_feedback?: QuickFeedbackTranscriptComponent;
  title: string;
}

export interface ButtonSelectionTranscriptComponent
  extends BaseTranscriptComponent {
  option_selected: string;
  options: { label: string; value: string }[];
}

export interface IntentTriggeredTranscriptComponent
  extends BaseTranscriptComponent {
  intent_id: string;
  intent_title: string;
}

export interface GotoWorkflowTranscriptComponent
  extends BaseTranscriptComponent {
  intent_id?: string;
  intent_title?: string;
  intent_workflow_id: string;
}

export interface ExecutionTranscriptComponent extends BaseTranscriptComponent {
  message: string;
  success?: boolean;
}

export type IndicatorTranscriptComponent = ExecutionTranscriptComponent;

export interface ImageTranscriptComponent extends BaseTranscriptComponent {
  description: string;
  url: string;
}

export interface EmbedTranscriptComponent extends BaseTranscriptComponent {
  embed_url: string;
}

interface Component {
  label?: string;
  value: string;
}
interface DisplayableDynamicSection {
  components: Component[];
  type: 'image' | 'content' | 'title';
}
export interface DynamicCardSelectionTranscriptComponent
  extends BaseTranscriptComponent {
  fields: {
    displayable_dynamic_sections: DisplayableDynamicSection[];
    value: string;
  };
}

export type DynamicCardTranscriptComponent = BaseTranscriptComponent &
  DynamicCardTranscriptWidgetComponent;

export interface CsatSubmissionTranscriptComponent
  extends BaseTranscriptComponent {
  additional_feedback?: string;
  csat_feedback?: string[];
  csat_resolve?: boolean;
  csat_score: string;
}

export type TranscriptComponent =
  | TextTranscriptComponent
  | RelatedArticlesTranscriptComponent
  | ButtonSelectionTranscriptComponent
  | IntentTriggeredTranscriptComponent
  | ExecutionTranscriptComponent
  | ArticleCardTranscriptComponent
  | ImageTranscriptComponent
  | EmbedTranscriptComponent
  | DynamicCardSelectionTranscriptComponent
  | CsatSubmissionTranscriptComponent
  | QuickFeedbackTranscriptComponent
  | GotoWorkflowTranscriptComponent;

export type ArticleTranscriptComponent =
  | RelatedArticlesTranscriptComponent
  | ArticleCardTranscriptComponent;

export const isArticleCardComponent = (
  comp: TranscriptComponent,
): comp is ArticleCardTranscriptComponent => {
  return comp.message_type === 'article_card';
};

export const isRelatedArticlesTranscriptComponent = (
  comp: TranscriptComponent,
): comp is RelatedArticlesTranscriptComponent => {
  return comp.message_type === 'related_articles';
};

// UI types
export type MessageBubblePosition =
  | 'lone'
  | 'first-in-a-batch'
  | 'middle'
  | 'last-in-a-batch';
