import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { getRecommendedFix } from './utils';
import { ErrorQueryEvent } from 'src/types/ErrorEventTypes';

export const RecommendedFix = ({ error }: { error: ErrorQueryEvent }) => {
  const { palette } = useTheme();

  const CurrentErrorFix = getRecommendedFix(error);

  return (
    CurrentErrorFix && (
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography variant='font11'>How to fix</Typography>
        <Box
          border={`1px solid ${palette.colors.slate[200]}`}
          borderRadius='8px'
          display='flex'
          flexDirection='column'
          gap='16px'
          padding='16px'
        >
          {CurrentErrorFix}
        </Box>
      </Box>
    )
  );
};
