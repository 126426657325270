import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { DeepLink } from './DeepLink';
import { BaseErrorPayload, ErrorQueryEvent } from 'src/types/ErrorEventTypes';

export const HowToFixSection = ({
  channel,
  children,
  error_payload,
  showDeepLink = true,
}: {
  channel: ErrorQueryEvent['channel'];
  children: ReactNode;
  error_payload: BaseErrorPayload;
  showDeepLink?: boolean;
}) => {
  return (
    <>
      {children}
      {showDeepLink && (
        <Box mt={1}>
          <DeepLink
            channel={channel}
            intentDefinitionId={error_payload.intent_definition_id}
            stepId={error_payload.step_id}
            workflowId={error_payload.workflow_id}
          />
        </Box>
      )}
    </>
  );
};
